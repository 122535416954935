import React, { useCallback, useState } from 'react'
import { I18nHtml, Modal, postMessageScrollTop } from 'shared'

export const LegalInformation = (): JSX.Element => {
  const [showModal, setShowModal] = useState(false)
  const hideModal = useCallback(() => {
    setShowModal(false)
  }, [])

  const openModal = useCallback(() => {
    setShowModal(true)
  }, [])

  return (
    <>
      <span className="block mb-4">
        {i18n.t('shared.newsletter_consent.platform.label_prefix.homepage')}{' '}
        <span
          className="gentle-link larger-click-area"
          role="button"
          onKeyPress={openModal}
          tabIndex={0}
          onClick={openModal}
        >
          {i18n.t('shared.newsletter_consent.platform.consent_modal_opener_label')}
        </span>{' '}
        {i18n.t('shared.newsletter_consent.platform.label_suffix')}
      </span>
      <Modal
        id="betterplace-newsletter-subscription-legal-modal"
        onHide={hideModal}
        onShow={postMessageScrollTop}
        show={showModal}
        header={i18n.t('shared.newsletter_consent.platform.legal_modal.heading')}
        footer={
          <button className="my-1 btn btn-primary" onClick={hideModal} type="button">
            {i18n.t('misc.close')}
          </button>
        }
      >
        <I18nHtml i18nKey="shared.newsletter_consent.platform.legal_modal.content" />
      </Modal>
    </>
  )
}
