import React, { useCallback } from 'react'
import { Captcha } from 'shared'
import { FormCaptchaProps } from './types'

const CAPTCHA_SOLUTION_FIELD_NAME = 'captcha_solution'
const CAPTCHA_ERROR_FIELD_NAME = 'captcha_error'
const CAPTCHA_COMPLETE = '_captcha_complete'

export const FormCaptcha = ({ setFieldValue, sitekey, puzzleEndpoint }: FormCaptchaProps): JSX.Element => {
  const onCaptchaSuccess = useCallback(
    (solution) => {
      void setFieldValue(CAPTCHA_COMPLETE, true)
      void setFieldValue(CAPTCHA_SOLUTION_FIELD_NAME, solution)
    },
    [setFieldValue]
  )

  const onCaptchaError = useCallback(
    (error, value) => {
      void setFieldValue(CAPTCHA_COMPLETE, true)
      void setFieldValue(CAPTCHA_ERROR_FIELD_NAME, value)
      console.log(error)
    },
    [setFieldValue]
  )

  const onCaptchaReset = useCallback(() => {
    // this causes a problem, because it invalidates the form again
    // and therefore replaces the backend error
    // therefore we cannot have two different error messages
    // until we rebuild the whole form error handling
    void setFieldValue(CAPTCHA_COMPLETE, false)
  }, [setFieldValue])

  return (
    <div className="form-group col-md-12">
      <Captcha
        siteKey={sitekey}
        endpoint={puzzleEndpoint}
        onSuccess={onCaptchaSuccess}
        onError={onCaptchaError}
        onReset={onCaptchaReset}
        fieldName={CAPTCHA_SOLUTION_FIELD_NAME}
        invertColors
      />
    </div>
  )
}
