import React from 'react'
import { A11y, Mousewheel, Navigation, Pagination } from 'swiper/modules'
import { BannerProps, BannerSwiperProps } from './types'
import { NavigationArrow } from 'shared'
import { Swiper, SwiperSlide } from 'swiper/react'

export const BannerSwiper = ({ banners }: BannerSwiperProps): JSX.Element => {
  if (banners.length === 0) return <></>
  if (banners.length === 1 && banners[0]) return <Banner index={0} {...banners[0]} />

  const slidesPerView = 2
  const showNavigationArrows = banners.length > slidesPerView

  return (
    <div className="relative">
      <div className="generic-teaser-swiper select-none">
        {showNavigationArrows && (
          <div className="swiper-navigation swiper-navigation-left">
            <NavigationArrow id="banner-list-prev" direction="left" />
          </div>
        )}
        <Swiper
          modules={[Navigation, Pagination, Mousewheel, A11y]}
          spaceBetween={40}
          slidesPerView={1}
          pagination={{ clickable: true }}
          navigation={{
            nextEl: `#banner-list-next`,
            prevEl: `#banner-list-prev`,
            disabledClass: 'is-disabled',
          }}
          roundLengths
          mousewheel={{ forceToAxis: true }}
          breakpoints={{ 451: { slidesPerView } }}
        >
          {banners.map((banner, index) => (
            <SwiperSlide key={banner.id}>
              <Banner index={index} {...banner} />
            </SwiperSlide>
          ))}
        </Swiper>
        {showNavigationArrows && (
          <div className="swiper-navigation swiper-navigation-right">
            <NavigationArrow direction="right" id="banner-list-next" />
          </div>
        )}
      </div>
    </div>
  )
}

const Banner = ({ url, picture_url, alt_text }: BannerProps): JSX.Element => {
  return (
    <div className="item swiper-slide">
      <a href={url}>
        <img alt={alt_text} src={picture_url} />
      </a>
    </div>
  )
}
