import { dataLayer } from 'shared'

export const EETeaserClicktracking = () => {
  const teasers: NodeListOf<HTMLElement> = document.querySelectorAll('[data-ee-promo-attributes]')
  teasers.forEach((teaser) => {
    teaser.addEventListener('click', () => {
      dataLayer.push({
        event: 'eeTeaserClick',
        ecommerce: {
          promoClick: {
            promotions: [JSON.parse(teaser.dataset?.eePromoAttributes ?? '')],
          },
        },
      })
    })
  })
}
