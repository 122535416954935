import React from 'react'
import styles from './news_teaser_list.module.css'
import { A11y, Mousewheel, Navigation, Pagination } from 'swiper/modules'
import { BlogList, NewsTeaserListProps } from './types'
import { NewsTeaser, themeBreakpoint, useJsonApi } from 'shared'
import { Swiper, SwiperSlide } from 'swiper/react'

export const NewsTeaserList = ({ url }: NewsTeaserListProps): JSX.Element | null => {
  const { data: blogList } = useJsonApi<BlogList>(url)
  const breakpoint = themeBreakpoint()
  const slidesPerView = 3
  if (!blogList || !blogList.length) return null
  if (blogList.length === 1 && blogList[0]) {
    const blog = blogList[0]
    return (
      <div className={styles.singleResultContainer}>
        <NewsTeaser news={blog} key={blog.published_at} />
      </div>
    )
  }

  return (
    <div className="generic-teaser-swiper select-none">
      <Swiper
        modules={[A11y, Mousewheel, Navigation, Pagination]}
        pagination={{ clickable: true }}
        roundLengths
        mousewheel={{ forceToAxis: true }}
        spaceBetween={16}
        breakpoints={{
          [breakpoint]: {
            slidesPerView,
          },
        }}
      >
        {blogList.map((blog) => (
          <SwiperSlide key={blog.published_at}>
            <NewsTeaser news={blog} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}
