import React, { useCallback, useState } from 'react'
import * as Yup from 'yup'
import { Button, JSONFetcher } from 'shared'
import { Field, Form, Formik } from 'formik'
import { FormCaptcha } from './form_captcha'
import { LegalInformation } from './legal_information'
import { NewsletterSubmitHandler, SubscribeFormProps } from './types'

export const SubscribeForm = ({
  newsletterSubscribeUrl,
  email,
  sitekey,
  puzzleEndpoint,
}: SubscribeFormProps): JSX.Element => {
  const [loading, setLoading] = useState(false)
  const [submitted, setSubmitted] = useState(false)

  const submitHandler: NewsletterSubmitHandler = useCallback(
    (values, formikBag) => {
      setLoading(true)
      JSONFetcher.post({
        url: newsletterSubscribeUrl,
        body: {
          ...values,
          target_type: 'CommunityNewsletter',
          wording: i18n.t('homepages.newsletter_box.introduction'),
        },
        success: (response: { error?: string }) => {
          setLoading(false)
          if (response.error) {
            formikBag.setFieldError('email', response.error)
          } else {
            setSubmitted(true)
          }
        },
      })
    },
    [newsletterSubscribeUrl]
  )

  const error = i18n.t('homepages.newsletter_box.form_email_error')

  const emailSchema = () =>
    Yup.object().shape({
      email: Yup.string().email(error).max(255, error).required(error),
    })

  if (submitted)
    return (
      <div className="submitted">
        <h2>{i18n.t('homepages.newsletter_box.optin_headline')}</h2>
        <p>{i18n.t('homepages.newsletter_box.optin_introduction')}</p>
      </div>
    )

  return (
    <Formik validationSchema={emailSchema} initialValues={{ email }} onSubmit={submitHandler}>
      {({ touched, errors, setFieldValue }) => (
        <>
          <LegalInformation />
          <Form>
            <div className="row">
              <div className="form-group col-md-12">
                <Field
                  className="form-control form-control-lg"
                  name="email"
                  placeholder={i18n.t('homepages.newsletter_box.form_email_placeholder')}
                  type="text"
                />
                {touched.email && <small>{emailErrorMsg(errors.email)}</small>}
              </div>

              <div className="form-group col-md-12">
                <Button className="btn-primary btn-large" smallSpinner loading={loading}>
                  {i18n.t('homepages.newsletter_box.form_submit_button')}
                </Button>
              </div>
              <FormCaptcha setFieldValue={setFieldValue} sitekey={sitekey} puzzleEndpoint={puzzleEndpoint} />
            </div>
          </Form>
        </>
      )}
    </Formik>
  )
}

const emailErrorMsg = (error?: string) => error
