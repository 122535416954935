import './base'
import { BannerSwiper, EETeaserClicktracking, NewsTeaserList, SubscribeForm } from '../homepage'
import { ProjectSwiper } from 'modules'
import { ProjectSwiperProps } from 'modules/project_swiper/types'
import { createElement } from 'react'
import { mountOnId } from 'shared'
import { render } from 'react-dom'

document.addEventListener('DOMContentLoaded', () => {
  const mountBanners = document.getElementById('mount-homepages-banners')
  if (mountBanners) {
    const banners = JSON.parse(mountBanners.getAttribute('data-banners') ?? '[]')
    const bannerComponent = createElement(BannerSwiper, { banners })
    render(bannerComponent, mountBanners)
  }

  mountOnId(NewsTeaserList, 'homepages-news-teaser-list')
  const swipers: NodeListOf<HTMLElement> = document.querySelectorAll('[data-behavior="project-swiper"]')
  swipers.forEach((el) => {
    const featured = createElement(ProjectSwiper, el.dataset as ProjectSwiperProps)
    render(featured, el)
  })

  mountOnId(SubscribeForm, 'mount-homepages-newsletter-form')

  EETeaserClicktracking()
})
